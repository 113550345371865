<template>
  <div class="OrderDetail">
    <!-- 订单详情头部 -->
    <div class="order-header">
      <div class="order-header-content">
          <p>订单详情</p>
      </div>
    </div>


    <div class="commodityInfo">
      <div class="clearfix">
        <img class="comImg"  :src="currentOrder.skuImage" />
        <div class="commodityBox">
          <div class="commodity-tit">{{currentOrder.commodityInfo}}</div>
          <div class="commodity-sku">规格: {{currentOrder.specifications}} | {{currentOrder.leaseDuration}}月</div>
        </div>
      </div>
    </div>

    <div class="rentBox" v-if="(currentOrder.orderState==4 || currentOrder.orderState==5 || currentOrder.orderState==6) && currentOrder.orderInstallment">
      <div class="rent-money">￥<span>{{currentOrder.orderInstallment.needPaymentAmount}}</span>
      </div>
      <div class="p_time" v-if="!(currentOrder.orderInstallment.lastIssue==1 && currentOrder.orderInstallment.installmentState==2)">第 {{currentOrder.orderInstallment.leaseTerm}}/{{currentOrder.leaseDuration}}期 | 付租日{{currentOrder.orderInstallment.leaseStartTime.substring(0, 10)}}</div>
      
      <div class="plan_time">租赁生效时间：{{currentOrder.leaseStartTime}}
      </div>
      <div class="planBox"
        v-if="(currentOrder.orderInstallment.installmentState==0 && currentOrder.orderInstallment.isPossibleToPay) || currentOrder.orderInstallment.installmentState==1">
        <el-button
        round
        type="primary"
        style="margin-top:4px"
        @click="sendPay()"
      >
        支付租金
      </el-button>
    </div>
    </div>


    <div class="maincontent">
      <div class="costBox">
        <div class="cost-tit">租赁期限</div>
        <div class="order-li">
          <span class="left-li">生效时间:</span>
          <span class="right-li">{{currentOrder.validTime}}<p v-if="currentOrder.orderState<4 || currentOrder.orderState==8">(预计)</p></span>
        </div>
        <div class="order-li">
          <span class="left-li">到期时间:</span>
          <span class="right-li">{{currentOrder.dueTime}}<p v-if="currentOrder.orderState<4 || currentOrder.orderState==8">(预计)</p></span>
        </div>
      </div>

      <div class="costBox">
        <div class="cost-tit">租金信息</div>
        <div class="order-li">
          <span class="left-li">首期租金:</span>
          <span class="right-li">￥{{currentOrder.firstRent}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">月租金:</span>
          <span class="right-li">￥{{currentOrder.rent}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">租期:</span>
          <span class="right-li">{{currentOrder.leaseDuration}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">设备毁损赔偿:</span>
          <span class="right-li">{{currentOrder.buyoutPrice}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">租期总额:</span>
          <span class="right-li">￥{{currentOrder.totalLeaseTerm}}</span>
        </div>
        <div class="order-li guarantee" v-if="Number(currentOrder.depositAmount)>0">
          <span class="left-li">履约保证金:</span>
          <span class="right-li">￥{{currentOrder.depositAmount}}</span>
        </div>
        <div class="order-li guarantee" v-if="Number(currentOrder.depositAmount)>0">
          <span class="left-li" style="color: #F85340">(履约保证金如未使用将在租赁合同完全履行后予以退还)</span>
        </div>
      </div>
 
      <div class="costBox" v-if="currentOrder.isRentRenewal==0"  >
        <div class="cost-tit">押金费用</div>
        <div class="order-li">
          <span class="left-li">总押金:</span>
          <span class="right-li">￥{{currentOrder.depositPrice}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">信用抵扣:</span>
          <span class="right-li">￥{{currentOrder.creditDeduction}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">实付押金:</span>
          <span class="right-li">￥{{currentOrder.paidDeposit}}</span>
        </div>
      </div>
      

      <div class="titleList" v-if="currentOrder.orderState==0 || currentOrder.orderState==1">
        <div class="leftTitle">待付金额:</div>
        <div class="rightArrow" style="color: #F85340">
          <p>{{currentOrder.actualPaymentAmount}}</p>
        </div>
      </div> 
      <div class="titleList" v-if="Number(currentOrder.lateFee)>0">
        <div class="leftTitle">待付滞纳金额:</div>
        <div class="rightArrow" style="color: #F85340">
          <p>{{currentOrder.lateFee}}</p>
        </div>
      </div>

      <div class="costBox">
        <div class="cost-tit">订单信息</div>
        <div class="order-li">
          <span class="left-li">订单编号:</span>
          <span class="right-li">{{currentOrder.orderNumber}}</span>
        </div>
        <div class="order-li" v-if="currentOrder.commoditySn">
          <span class="left-li">设备序列号:</span>
          <span class="right-li">{{currentOrder.commoditySn}}</span>
        </div>
        <div class="order-li">
          <span class="left-li">创建时间:</span>
          <span class="right-li">{{currentOrder.orderCreateTime}}</span>
        </div>
        <div class="order-li" v-if="currentOrder.orderState>=2">
          <span class="left-li">支付时间:</span>
          <span class="right-li">{{currentOrder.payTime}}</span>
        </div>
        <div class="order-li" v-if="currentOrder.orderState>=3">
          <span class="left-li">发货时间:</span>
          <span class="right-li">{{currentOrder.deliveryTime}}</span>
        </div>
        <div class="order-li" v-if="currentOrder.orderState>=3">
          <span class="left-li">物流单号:</span>
          <span class="right-li">{{currentOrder.logisticsOrderNo}}</span>
        </div>
      </div>
      
      <div class="aggrement">
        <div class="spanbtn" ><a href="https://file.doowinfintec.com/%E6%9A%96%E6%9A%96%E7%A7%9F%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%20.pdf"> 《暖暖租隐私政策》</a></div>
        <div class="spanbtn" ><a @click="serviceAggrementshow = true">《用户信息查询授权书》</a></div>
        <div class="spanbtn" v-if="currentOrder.contractId" >
            <span v-if="currentOrder.isRentRenewal==1"><a :href=currentOrder.contractDownloadUrl>《暖暖租到期续租协议》</a></span>
            <span v-else ><a :href=currentOrder.contractDownloadUrl>《暖暖租租赁服务协议》</a></span>
        </div>
      </div>
      
      <div class="quanping" v-if="serviceAggrementshow" @click="serviceAggrementshow = false">
        <div class="serviceAggrement">
          <h3>用户信息查询授权书</h3>
          <div class="content">
            <div class="service-detail">授权人声明：</div>
            <div class="service-detail">姓名：{{currentOrder.userName ? currentOrder.userName : ""}}</div>
            <div class="service-detail">身份证号码：{{currentOrder.idCardNum ? currentOrder.idCardNum : ""}}</div>
            <div class="service-detail">本人在暖暖租平台上已仔细阅读本授权书全部内容，对本授权书所有条款及相应的法律后果已全部知晓并完全理解，明白勾选同意后，本授权书即视为本人签署，构成具有约束力的法律文件，授权期限自提交订单至在暖暖租平台业务完结为止。</div>
            <div class="service-detail">本人同意并不可撤销地授权【温州瀚图科技有限公司】，在【信用租赁】业务过程中（从业务申请至业务终止），基于约定用途：包括但不限于租前审核、租后管理、担保或依法或经有权部门要求等，有权通过第三方服务机构（包括但不限于中国人民银行征信中心等第三方权威征信机构、第三方服务机构及其关联公司以及其他第三方数据机构等）对本人提交的信息进行核实；有权通过前述第三方服务机构采集、使用本人的个人信息（包含本人的姓名、身份证号、银行卡号、公积金账户、芝麻信用信息、教育信息、联系人信息、车辆信息、驾照信息、出行信息、消费信息和消费行为、工作信息、社交信息、财产信息、资信状况、就业情况、收入情况、婚姻情况、地址信息、位置数据等），本人的通讯方式（包括但不限于固话、手机、宽带网络等）、通讯信息（包括但不限于通话纪录、套餐使用情况、浏览网页情况等），包括可能对信息主体产生负面影响的不良信息，以判断、识别业务风险。</div>
            <div class="service-detail">本人同意并不可撤销地授权【温州瀚图科技有限公司】向第三方服务机构报送、提供、共享被授权人通过相关渠道获取的本人信息，并授权第三方机构对获取的本人的信息依法进行相关处理（包括但不限于整理、保存、加工、提供）等其他合法用途。</div>
            <div class="service-detail">本人承诺本授权经签署后立即生效，且效力具有独立性，不因其他合同的任何条款无效而失效。</div>
            <div class="service-detail">本人已知悉本授权书所有内容的意义及由此产生的法律效力，自愿做出上述授权，本授权申明是本人真实的意思表示，本人同意承担由此带来的一切法律后果。</div>
            <p class="last-foot">授权人：{{currentOrder.userName ? currentOrder.userName : ""}}</p>
            <p class="last-foot">授权日期：{{currentOrder.orderCreateTime.substring(0,10)}}</p>
          </div>
        </div>
      </div>


      <div class="costBox" v-if="(currentOrder.orderState==4 || currentOrder.orderState==5 || currentOrder.orderState==6) && currentOrder.orderInstallment">
        <el-button
          type="warning"
          style=" margin: 10px auto 0; display: block;"
          @click="goBuyout()"
        >
          设备毁损
        </el-button>
      </div>

   
 
     

   
    </div>

  </div>
</template>
<script>

// import orderList from "../components/orderList.vue";
export default {
    data() {
        return {
            orders: [],
            total: [],
            activeName: "all",
            orderList: [],
            currentOrder: {},
            orderId: '',
            serviceAggrementshow:false
        };
    },
    activated() {
        if (this.$route.query.orderId != undefined) {
            this.orderId = this.$route.query.orderId;
            console.log(this.orderId);
        }
    },
    // components: { orderList },
    created() {
    },
    beforeDestroy() {
        this.orderList = [];
    },
    watch: {
        // 监听商品id的变化，请求后端获取商品数据
        orderId: function () {
            this.getcurrentOrder();
        },
    },
    methods: {
      getcurrentOrder() {
        // 获取订单详情
        this.$axios
            .post("/zuwu-api/web/order/detail", { orderId: this.orderId })
            .then(({ data }) => {
            if (data.code == 200 && data.success) {
                this.currentOrder = data.data;
            }
        });
      },

      // 支付租金
      sendPay(){
        var currentOrder = this.currentOrder
        if([null,"","0.00"].includes(currentOrder.orderInstallment.needPaymentAmount)){
          this.$message.success("已支付全部的租金,无需操作")
          return
        }
        let num = currentOrder.orderInstallment.leaseTerm;
        let title = `【${currentOrder.commodityInfo}】第${num}期租金`,
          amount = currentOrder.orderInstallment.needPaymentAmount,
          payCause = 1,
          useCashDeposit = false,
          cashDeposit = null,
          usePhoneWebPay = true;
        let barginFlag = currentOrder.depositPeriodList.split(",");
        let arr = [];
        barginFlag.forEach((item) => {
          arr.push(Number(item));
        });

        let reqData = {
          expandParams: {
            orderInstallmentId: currentOrder.orderInstallment.id || "",
            leaseTerm: num,
            cashDeposit: cashDeposit,
          },
          useCashDeposit: useCashDeposit,
          payCause: payCause,
          totalAmount: amount,
          subject: title,
          orderId: currentOrder.orderId,
          usePhoneWebPay,
        };
        this.payment(reqData);
      },
    // 设备毁损
    goBuyout() {
      var currentOrder = this.currentOrder
      this.$axios
      .post(`/zuwu-api/order/isAllowBuyout/${this.orderId}`)
      .then(({ data }) => {
        if (data.code == 200 && data.msg == "允许买断") {
          this.$axios
            .get(`/zuwu-api/order/buyCompletely/${this.orderId}`)
            .then(({ data: buyOutData }) => {
              let buyOutInfo = buyOutData.data;
              let reqData = {
                expandParams: {
                  cashDeposit:
                    buyOutInfo.depositSurplusAmount == 0
                      ? null
                      : buyOutInfo.depositSurplusAmount,
                },
                // 是否(可)用保证金支付
                useCashDeposit:
                  buyOutInfo.depositSurplusAmount == 0 ? false : true,
                payCause: 2,
                totalAmount: buyOutInfo.paymentBuyoutPrice,
                subject: `【${currentOrder.commodityInfo}】毁损金额`,
                orderId: buyOutInfo.orderId,
                usePhoneWebPay: true,
              };
              this.payment(reqData);
            });
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    payment(reqData) {
    this.$axios
      .post("/zuwu-api/orderPay/getPayTradeNo", reqData)
      .then(({ data: payData }) => {
        if (payData.code == 200 && payData.success) {
          let { isNeedPay, payVoucher,showMessage } = payData.data;
          if (isNeedPay) {
            let divDom = document.createElement("div");
            divDom.innerHTML = payVoucher;
            document.body.appendChild(divDom);
            let form = divDom.getElementsByTagName("form")[0];
            form.submit();
            document.body.removeChild(divDom);
          } else {
            this.$message.success(showMessage);
          }
        } else {
          this.$message.warning(payData.msg);
        }
      });
    },
  },
};
</script>
<style scoped>

@import "../assets/css/index.css";
</style>
